<template>
<h1 class="text-center">Tabs</h1>
  <ul>
    <li  class="mt-10 text-3xl">
      <p class="py-8">Nr.1</p>
      <TabNr1/>
    </li>
    <li  class="mt-10 text-3xl">
      <p class="py-8">Nr.2</p>
      <TabNr2/>
    </li>
    <li  class="text-3xl">
      <p class="py-8">Nr.3</p>
      <TabNr3/>
    </li>
    <li  class="text-3xl">
      <p class="py-8">Nr.4</p>
      <TabNr4/>
    </li>

    <li  class="text-3xl">
      <p class="py-8">Nr.5</p>
      <TabNr5/>
    </li>
  </ul>
</template>

<script>

import TabNr1 from "../../components/partials/Tabs/TabNr1";
import TabNr2 from "../../components/partials/Tabs/TabNr2";
import TabNr3 from "../../components/partials/Tabs/TabNr3";
import TabNr4 from "../../components/partials/Tabs/TabNr4";
import TabNr5 from "../../components/partials/Tabs/TabNr5";
export default {
  name: "Tabs",
  components:{TabNr1,TabNr2,TabNr3,TabNr4,TabNr5}

}
</script>
