<template>
  <section>
  <figure class="tabBlock">
    <svg hidden xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <symbol id="icon-home" viewBox="0 0 576 512"><path fill="currentColor" d="M280.37 148.26L96 300.11V464a16 16 0 0016 16l112.06-.29a16 16 0 0015.92-16V368a16 16 0 0116-16h64a16 16 0 0116 16v95.64a16 16 0 0016 16.05L464 480a16 16 0 0016-16V300L295.67 148.26a12.19 12.19 0 00-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 00-12-12h-56a12 12 0 00-12 12v72.61L318.47 43a48 48 0 00-61 0L4.34 251.47a12 12 0 00-1.6 16.9l25.5 31A12 12 0 0045.15 301l235.22-193.74a12.19 12.19 0 0115.3 0L530.9 301a12 12 0 0016.9-1.6l25.5-31a12 12 0 00-1.7-16.93z"/></symbol>
      <symbol id="icon-vue" viewBox="0 0 448 512"><path fill="currentColor" d="M356.9 64.3H280l-56 88.6-48-88.6H0L224 448 448 64.3h-91.1zm-301.2 32h53.8L224 294.5 338.4 96.3h53.8L224 384.5 55.7 96.3z"/></symbol>
      <symbol id="icon-nuxt" viewBox="0 0 24 24"><path d="M19.724 20.272l.023-.045a.74.74 0 00.053-.117l.002-.005a1.16 1.16 0 00.054-.607l.001.007a1.795 1.795 0 00-.235-.623l.005.008-5.317-9.353-.812-1.42-.807 1.42-5.314 9.353a1.85 1.85 0 00-.204.604l-.001.011a1.257 1.257 0 00.08.713l-.003-.008a.809.809 0 00.05.102l-.002-.004c.16.275.5.602 1.25.602h9.898c.157 0 .925-.032 1.28-.637zm-6.227-9.316l4.859 8.548H8.64zm10.249 7.934L16.73 6.53c-.072-.13-.477-.787-1.182-.787-.317 0-.772.135-1.142.785l-.907 1.59.807 1.42 1.25-2.212 6.941 12.18h-2.64a1.187 1.187 0 01-.058.608l.003-.008a.772.772 0 01-.057.126l.002-.004-.023.045c-.355.605-1.122.637-1.272.637h4.129c.152 0 .917-.032 1.272-.637.157-.275.27-.737-.107-1.382zM7.304 20.307a.989.989 0 01-.045-.092l-.002-.006a1.228 1.228 0 01-.084-.712l-.001.007H1.501L9.93 4.672l2.767 4.864.802-1.42-2.412-4.249c-.067-.122-.475-.777-1.177-.777-.317 0-.772.137-1.142.787L.23 18.889c-.072.13-.425.812-.075 1.417.16.275.5.602 1.25.602h7.151c-.745 0-1.09-.322-1.25-.602z"/></symbol>
    </svg>
    <ul class="tabBlock-tabs">
      <li
          v-for="(tab, index) in tabs"
          :key="index"
          :aria-setsize="tabs.length"
          :aria-posinet="index + 1"
      >
        <a
            href="javascript:void(0);"
            class="tabBlock-tab text-2xl"
            :class="active_tab === index ? 'is-active' : ''"
            :aria-selected="active_tab === index"
            @click="changeTab(index)"
        >
          <svg aria-hidden="true">
            <use :xlink:href="'#icon-' + tab.tab_icon"></use>
          </svg>
          {{ tab.tab_title }}
        </a>
      </li>
    </ul>
    <div class="tabBlock-content">
      <div
          v-for="(tab, index) in tabs"
          :key="index"
          :aria-current="active_tab === index"
          class="tabBlock-pane text-3xl"
          v-show="active_tab === index"
      >
        <p>{{ tab.tab_content }}</p>
      </div>
      <hr />
      <div class="tabBlock-pane text-2xl">
        <p>
          You can click on each tab to show the content <strong>or</strong> you can tab to a tab item and press the enter to switch tabs, making it accessible.
        </p>
        <p>For more accessibility, take note of the <code>aria-selected=""</code> attribute on the tabs - you can read more <a href="https://www.digitala11y.com/aria-current-state" target="_blank" rel="nofollow noopener">here</a>.</p>
        <p>
          You can pass me anything, first try a string, then try the Vue.js <a href="https://vuejs.org/v2/guide/components-dynamic-async.html" rel="nofollow noopener" target="_blank">component tag</a>.
        </p>
        <small
        ><a href="https://jackdomleo.dev" rel="nofollow" target="_blank"
        >Jack Domleo</a></small>
      </div>
    </div>
  </figure>
  </section>
</template>

<script>



export default {
  data() {
    return {
      active_tab: 0,
      tabs: [
        {
          tab_title: "Home",
          tab_icon: "home",
          tab_content:
              "This is the home page. Welcome young padawan! Your Vue.js training begins here..."
        },
        {
          tab_title: "Vue.js",
          tab_icon: "vue",
          tab_content:
              "Vue.js is an open-source Model–view–viewmodel JavaScript framework for building user interfaces and single-page applications. It was created by Evan You, and is maintained by him and the rest of the active core team members coming from various companies such as Netlify and Netguru. - Wikipedia"
        },
        {
          tab_title: "Nuxt.js",
          tab_icon: "nuxt",
          tab_content:
              'Nuxt.js is a free and open source web application framework based on Vue.js, Node.js, Webpack and Babel.js. The framework is advertised as a "meta-framework for universal applications". - Wikipedia'
        }
      ]
    };
  },
  methods: {
    changeTab(tabIndexValue) {
      this.active_tab = tabIndexValue;
    }
  }
};
</script>

<style scoped lang="scss">


section {
  color: #222;
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 50rem;
  padding: 2.5rem 1.25rem;
}

/* ===================================================== */

$primary: darkorange;
$primary-faint: mix($primary, #fff, 50%);
$border: #d8d8d8;

.tabBlock {

  &-tabs {
    list-style: none;
    margin: 0;
    padding: 0;

    &::after {
      clear: both;
      content: "";
      display: table;
    }
  }

  &-tab {
    background-color: #fff;
    border-color: $border;
    border-left-style: solid;
    border-top: solid;
    border-width: 1px;
    color: $primary-faint;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 600;
    float: left;
    padding: 0.625rem 1.25rem;
    position: relative;
    transition: 0.1s ease-in-out;
    text-decoration: none;

    &:last-of-type {
      border-right-style: solid;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      height: 4px;
      position: absolute;
      transition: 0.1s ease-in-out;
    }

    &::before {
      background-color: $primary-faint;
      left: -2px;
      right: -2px;
      top: -2px;
    }

    &::after {
      background-color: transparent;
      bottom: -2px;
      left: 0;
      right: 0;
    }

    &:hover,
    &:focus {
      color: $primary;
    }

    @media screen and (min-width: 700px) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    &.is-active {
      position: relative;
      color: $primary;
      z-index: 1;

      &::before {
        background-color: $primary;
      }

      &::after {
        background-color: #fff;
      }
    }

    svg {
      height: 1.2rem;
      width: 1.2rem;
      margin-right: 0.5rem;
      pointer-events: none;
      fill: currentcolor;
    }
  }

  &-content {
    background-color: #fff;
    border: 1px solid $border;
    padding: 1.25rem;

    a {
      color: dodgerblue;
      font-weight: 700;
      transition: color 200ms ease;

      &:hover,
      &:focus {
        color: orangered;
      }
    }

    hr {
      margin: 3rem 0;
      border: 1px solid transparent;
      border-radius: 50%;
      border-top-color: $border;
    }
  }
}
</style>