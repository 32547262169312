<template>

  <section class="text-left">
    <h1 class="py-12">Vue Tabs</h1>
  <div class="tabs" id="tabs">
    <div class="tabs-triggers">
      <div class="tabs-trigger" v-for="(item, index) in categories"
           :class="[index === active ? 'tabs-trigger--active' : '']"
           @click="activate(index)"
          :key="item">{{ categories[index] }}
      </div>
    </div>
    <div class="tabs-content" v-if="active === 0">Content 1</div>
    <div class="tabs-content" v-if="active === 1">Content 2</div>
    <div class="tabs-content" v-if="active === 2">Content 3</div>
  </div>
  </section>
</template>

<script>


export default {
  data() {
    return {
      active: 0,
      categories: [
        "Tab 1",
        "Tab 2",
        "Tab 3"
      ]
    };
  },
  methods: {
    activate(index) {
      this.active = index;
    }
  }
};
</script>

<style scoped lang="scss">

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700");

$base-duration: 250ms;

// Colors
$primary: #BC4B51;
$secondary: #EFCB68;
$accent: #686963;
$white: #ffffff;

$max-width: 1200px;
// Breakpoints
$sm: 20rem;
$med: 48rem;
$lg: 64rem;




section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
  background-color: $secondary;
  border: 1px solid black;
}

.tabs {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $white;

  &-triggers {
    display: flex;
  }

  &-trigger {
    flex: 1 0 auto;
    margin: 0;
    padding: 1rem;
    background-color: $accent;
    font-weight: bold;
    transition: 100ms linear all;
    cursor: pointer;

    &--active {
      background-color: $white;
      color: $primary;
    }
  }

  &-content {
    padding: 1rem;
    background-color: $white;
    color: black;

  }
}


</style>